import { ref, watch, watchEffect } from "vue";
import { projectFirestore, projectFunctions } from "../../../../../firebase/config";

const items = ref([])

const selected_supplier = ref(null)
const selected_supplier_name = ref(null)
const suppliers_list = ref([])

const get_suppliers_list = async() => {
    try{
        const docs = await projectFirestore.collection('Applications').doc('ZPWzy2s5SjbxIHG8XbcS')
        .collection('Suppliers').get()

        suppliers_list.value = docs.docs.map(doc => ({name:doc.data().supplier_name,num:doc.data().supplier_num}))
        console.log(suppliers_list.value)
    }catch(err){
        throw new Error(err)
    }
}

const get_items_by_supplier = async(supplier_num) => {
    try{
        await projectFunctions.httpsCallable("getComaxDataBysupplierNum",{timeout: 540000})({supplier_num});
    }catch(err){
        throw new Error(err)
    }
}

watch(selected_supplier,()=>{
    const index = suppliers_list.value.findIndex(({num}) => num == selected_supplier.value)
    if(index != -1) selected_supplier_name.value = suppliers_list.value[index].name
    else selected_supplier_name.value = null
    console.log(selected_supplier_name.value)
})

export{
    get_suppliers_list,
    suppliers_list,
    selected_supplier,
    selected_supplier_name,
    items,
    get_items_by_supplier
}